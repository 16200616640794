.wordContainer {
    width: 100%;
    height: 25px;
    min-height: 25px;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    border: 1px solid var(--border);
    box-sizing: border-box;
    font-weight: 500;
}

.wordFill {
    height: 25px;
    border-radius: 25px;
    overflow: hidden;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.wordFront {
    height: 25px;
    margin: 0 15px;
    user-select: none;
    color: var(--fg-dark);
    z-index: 2;
}

.wordBack {
    position: absolute;
    height: 25px;
    margin: 0 15px;
    user-select: none;
    color: var(--fg);
    z-index: 1;
}

.wordPercentage {
    position: absolute;
    bottom: 5px;
    right: 10px;
    user-select: none;
    color: var(--fg);
    z-index: 3;
    line-height: 13px;
}