.root {
    display: flex;
    flex-direction: column;
    height: calc(100% - 8px);
    gap: 12px;
    transition: transform .4s ease-in-out;
    min-width: 100%;
}

.inputContainer {
    width: calc(100% - 32px);
    margin-bottom: 8px;
    max-width: 420px;
    margin: auto auto 8px auto;
    z-index: 5;
}
