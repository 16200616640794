.header {
    left: 0px;
    width: 100%;
    height: var(--header-height);
    box-sizing: border-box;
    padding-top: 12px;
    position: relative;
}

.title {
    font-size: 30px;
    text-transform: uppercase;
    font-family: Evolventa;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 5;

    @media only screen and (min-width: 1024px) {
        font-size: 42px;
    }
}



.info {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0px;
    top: 82px;
    cursor: pointer;
    user-select: none;
    z-index: 1;
}

.arrows {
    position: absolute;
    top: 140px;
    right: -10px;
    transform: scale(0.75);
}

.shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    background-color: rgba(30, 45, 59, 0.5);
    backdrop-filter: blur(8px);
}

.dialog {
    width: 261px;
    height: fit-content;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;
    background-color: var(--bg);
    border-radius: 20px;
    padding: 56px 29px 45px 29px;
    color: var(--fg);
    background-image: url(../../assets/paper05.png);
    background-blend-mode: soft-light;
    box-shadow: 2px 2px 10px 0px rgba(109, 109, 109, 0.2);
    font-size: 16px;
}

.cross {
    cursor: pointer;
    position: absolute;
    top: 22px;
    right: 29px;
}

.paperclip {
    position: absolute;
    top: -24px;
    left: -24px;
}

.scribble {
    position: absolute;
    bottom: 0px;
    right: -20px;
}

.smileyface {
    position: absolute;
    bottom: -24px;
    left: -20px;
    fill: var(--fg);
}

.dialogTitle,
.dialogSubtitle {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
}

.dialogSubtitle {
    font-family: Evolventa;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 2px;
    margin-bottom: 12px;
}

.contactBtn {
    outline: none;
    border: none;
    border-radius: 30px;
    height: 50px;
    width: 100%;
    font-size: 18px;
    transition: opacity .25s;
    cursor: pointer;
    color: var(--fg);
    font-weight: 500;
    font-family: Jost;
    background: var(--fg);
    color: var(--fg-dark);

    &:hover {
        opacity: 0.8;
    }
}

.contactsList {
    width: 100%;
    gap: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

.contactsSubList {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 16px;
    font-weight: 300;

    a {
        font-weight: 500;
        color: var(--fg);
    }
}

.contactsSubList:last-child {
    justify-content: end;
    align-items: end;
}

.rulesList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-weight: 500;
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 14px;

    p {
        margin: 0px;
        padding: 0px;
    }
}

.joinBtns {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    flex-wrap: nowrap;
    width: 100%;
    gap: 16px;
}

.joinBtn {
    outline: none;
    border: none;
    border-radius: 30px;
    height: 50px;
    width: 100%;
    font-size: 18px;
    transition: opacity .25s;
    cursor: pointer;
    font-weight: 500;
    font-family: Jost;
    background: var(--fg-dark);
    border: 1px solid var(--fg);
    color: var(--fg);
    transition: background-color .2s, color .2s;

    &:hover {
        background-color: var(--fg);
        color: var(--fg-dark);
    }
}

.joinLink {
    flex: 1;
    width: 100%;
}