.form {
    height: 40px;
    box-sizing: border-box;
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
}

.input {
    height: 40px;
    width: 100%;
    outline: none;
    border: none;
    box-sizing: border-box;
    line-height: 20px;
    font-size: 16px;
    border-radius: 30px;
    padding: 0 20px;
    font-family: Jost;
    background-color: var(--bg-alt);
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);

    &:disabled {
        background-color: var(--disabled);
    }
}

.inputButton {
    padding: 0;
    outline: none;
    min-height: 40px;
    min-width: 40px;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}

.inputButtonSvg {
    height: 40px;
    width: 40px;
}