@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'Evolventa';
    src: local('Evolventa'), url(./assets/Evolventa-Regular.otf) format('opentype');
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    --bg: rgb(30, 45, 59);
    --bg-alt: rgba(255, 254, 236, 1);
    --primary: #e9fc88;
    --secondary: #e9fc88;
    --fg: rgba(255, 254, 236, 1);
    --fg-dark: rgba(51, 67, 76, 1);
    --disabled: rgb(240, 240, 240);
    --border: rgba(151, 157, 155, 1);
    --border-alt: #edb4f8;

    color: var(--fg);
    font-family: Jost;
    font-size: 16px;
}