.wordsListContainer {
    max-width: 420px;
    width: calc(100% - 90px);
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: visible;
    padding-right: 4px;
}

.scrollContainer {
    height: auto;
    overflow-y: auto;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
