.lastWordContainer {
    margin-bottom: 20px;
}

.wordContainer {
    width: 100%;
    height: 30px;
    min-height: 30px;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    font-weight: 500;
    color: var(--fg);
    background: linear-gradient(89.98deg, #32518C 0.01%, #A05D82 23.01%, #DC6E3C 49%, #E3C24C 75.49%, #F2FC7C 99.99%);
}

.wordFill {
    position: absolute;
    left: 15px;
    bottom: 9px;
    overflow: hidden;
    z-index: 2;
    width: 100%;
    line-height: 13px;
    height: fit-content;
}

.wordPercentage {
    position: absolute;
    bottom: 9px;
    right: 10px;
    line-height: 13px;
    user-select: none;
    color: var(--fg-dark);
    z-index: 3;
}
