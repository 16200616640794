.container {
    margin: 0 8px;
    overflow: hidden;
    width: calc(100% - 16px);
    height: 45%;
    min-height: 45%;
    position: relative;
}

.svgContainer {
    height: 100%;
    margin: 0 auto;
    user-select: none;
    width: calc(100% - 16px);
    max-width: 480px;
}

.svgComponent {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.zoomControls {
    position: absolute;
    bottom: 8px;
    right: 9px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.plusBtn {
    width: 40px;
    height: 40px;
    user-select: none;
    cursor: pointer;
}

.wordText {
    user-select: none;
    cursor: default;
}

.counterContainer {
    position: absolute;
    bottom: 8px;
    left: 2px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 40px;
    font-size: 20px;
    font-weight: 500;
    font-size: 21px;
}

.counter {
    position: absolute;
}