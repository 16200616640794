.shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(30, 45, 59, 0.5);
    backdrop-filter: blur(8px);
}

.container {
    width: 100%;
    height: 100vh;
    max-width: 450px;
    height: fit-content;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
}

.subheading {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    font-family: Evolventa;
    text-align: center;
    padding-bottom: 8px;
    width: fit-content;
    margin: 0 auto;
    position: relative;
    font-size: 23px;
}

.subheading::after {
    width: 183px;
    height: 7px;
    content: '';
    background-image: url(../../../../../assets/underline.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 32px;
    right: 16px;
    z-index: 1;
    background-size: 100% 100%;
}

.rulesList {
    display: flex;
    flex-direction: column;
    width: calc(100% - 16px);
    margin: 0 8px;
    margin-top: 40px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: var(--fg-dark);
    justify-content: space-between;
    height: 450px;
}

.ruleContainer {
    text-align: center;
    width: 80%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 16px 20px;
    position: relative;
}

.tape10 {
    position: absolute;
    top: -36px;
    left: -35px;
}

.tape2 {
    position: absolute;
    top: -22px;
    left: 50%;
    transform: translate(-50%);
}

.tape18 {
    position: absolute;
    left: -30px;
    bottom: -30px;
}

.stars {
    position: absolute;
    bottom: -50px;
    left: 0px;
    fill: var(--fg);
}

.stars1 {
    position: absolute;
    bottom: -20px;
    right: -30px;
    transform: rotate(-20deg);
    fill: var(--fg);
}

.paperclip {
    position: absolute;
    top: -45px;
    right: -15px;
    transform: scale(0.7) rotate(120deg);
}

.backBtn {
    position: absolute;
    top: 0;
    right: 16px;
    cursor: pointer;
}

.filter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-image: url(../../../../../assets/paper05.png);
    background-blend-mode: soft-light;
    opacity: 0.2;
    pointer-events: none;
}