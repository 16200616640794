.container {
    min-width: 260px;
    width: calc(100% - 136px);
    max-width: 380px;
    height: fit-content;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 8;
    background-color: var(--bg);
    border-radius: 20px;
    padding: 30px;
    color: var(--fg);
    background-image: url(../../assets/paper05.png);
    background-blend-mode: soft-light;
}

.paperclip {
    position: absolute;
    top: -45px;
    left: 0px;
    transform: scale(0.75);
}

.shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
    background-color: rgba(30, 45, 59, 0.5);
    backdrop-filter: blur(8px);
}

.title {
    text-align: center;
    text-transform: uppercase;
    font-family: Evolventa;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 26px;
    margin: 0;
    position: relative;
}

.title::after {
    width: 183px;
    height: 7px;
    content: '';
    background-image: url(../../assets/underline-gradient.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 36px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    background-size: 100% 100%;
}

.stars {
    position: absolute;
    top: -20px;
    right: 30px;
    fill: rgba(245, 232, 119, 1);
    transform: rotate(160deg);
}

.subtitle {
    margin: 60px 0;
}

.description {
    font-size: 22px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    width: fit-content;
}

.crown {
    position: absolute;
    top: -20px;
    left: -16px;
}

.smileyface {
    position: absolute;
    top: 8px;
    right: -40px;
    fill: rgba(220, 146, 60, 1);
}

.btn {
    outline: none;
    border: none;
    border-radius: 30px;
    height: 50px;
    width: 100%;
    font-size: 18px;
    transition: opacity .25s;
    cursor: pointer;
    color: var(--fg);
    font-weight: 500;
    font-family: Jost;
    background: var(--fg);
    color: var(--fg-dark);
    margin-top: 16px;

    &:hover {
        opacity: 0.8;
    }
}

.cross {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
}

.filter {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 7;
    background-image: url(../../assets/paper05.png);
    background-blend-mode: soft-light;
    opacity: 0.2;
    pointer-events: none;
}

.joinBtns {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    gap: 16px;
}

.joinBtn {
    outline: none;
    border: none;
    border-radius: 30px;
    height: 50px;
    width: 100%;
    font-size: 18px;
    transition: opacity .25s;
    cursor: pointer;
    font-weight: 500;
    font-family: Jost;
    background: var(--fg-dark);
    border: 1px solid var(--fg);
    color: var(--fg);
    transition: background-color .2s, color .2s;

    &:hover {
        background-color: var(--fg);
        color: var(--fg-dark);
    }
}

.joinLink {
    flex: 1;
    width: 100%;
}
