.toast {
    position: fixed;
    top: 0px;
    width: 420px;
    text-align: center;
    background-color: var(--bg-alt);
    background-image: url(../../assets/paper05.png);
    background-blend-mode: darken;
    background-size: cover;
    color: var(--fg-dark);
    border-radius: 30px;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 24px;
    padding: 17px 0;
    font-weight: 500;
    left: 50%;
    transform: translate(-50%, -200px);
    animation: slidein 2s, slideout .25s 2s;
    user-select: none;
}

@keyframes slidein {
    0% {
        transform: translate(-50%, -200px);
    }

    10% {
        transform: translate(-50%, 12px);
        z-index: 15;
    }

    100% {
        transform: translate(-50%, 12px);
        z-index: 15;
    }
}

@keyframes slideout {
    0% {
        transform: translate(-50%, 12px);
    }

    100% {
        transform: translate(-50%, -200px);
    }
}