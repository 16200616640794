.container {
    width: calc(100% - 18px);
    height: 100%;
    max-width: 500px;
    margin: 0 auto;
    --header-height: 67px;
    overflow-x: visible;
    position: relative;
    padding: 0 4px;
}

.content {
    display: flex;
    min-width: 100%;
    height: calc(100% - var(--header-height));
}