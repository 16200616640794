
.root {
    height: 100dvh;
    background-color: var(--bg);
    background-image: url(assets/paper05.png);
    background-blend-mode: soft-light;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    outline: 1px solid var(--fg);
    border-radius: 30px;
    width: 10px;
    margin-block: 1px;
}

::-webkit-scrollbar-thumb {
    background: var(--fg);
    border-radius: 30px;
    background-clip: padding-box;
    width: 10px;
}